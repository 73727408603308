import * as tags from "./tags";
import {
    ACTOR_USER,
    APP_ID,
    BOT_DISPLAY_NAME,
    HUMAN,
    MARKET,
    MESSAGES,
    PDF_MAX_LINE_LENGTH,
    PDF_MESSAGE_FONT_SIZE,
    PDF_TITLE_FONT_SIZE,
    PDF_TITLE_Y_POS,
    PDF_TRANSCRIPT_FILENAME,
    PDF_X_OFFSET,
    PDF_Y_MARGIN,
    PDF_Y_OFFSET_MESSAGES,
    SHADOW_ROOT_ID,
    WS_CONNECTION_CLOSED,
    WS_CONNECTION_RECONNECTING,
    WS_WARNING_RETRY_ATTEMPTS
} from "../constants";
import { getCookie, getCookieParam, removeCookieParam } from "./cookie";
import {customMopinionButton} from "../logging";

export const openURL = (e, url, target = "_blank") => {
    if (e !== null) {
        e.preventDefault();
    }
    window.open(url, target);
};

export const transformChatMessages = (messages) => {
    let plainTextMessage = "";

    for (let message of messages) {
        if (message.actor !== undefined) {
            let decodedMessage = strip(htmlDecode(message.text));
            plainTextMessage += `${message.actor === "ACTOR_USER"
                ? "Ik"
                : message.agent_name ? message.agent_name : BOT_DISPLAY_NAME
                }: ${decodedMessage}\n`;
        }
    }
    return plainTextMessage;
};

export const createPdf = (messages) => {
    tags.chatDownload();
    return import('jspdf').then(jspdf => {
        const doc = new jspdf.jsPDF(), // defaults: A4 format, portrait landscape, mm measurement unit
            TODAY = new Date(),
            DATE_STRING = new Intl.DateTimeFormat("nl-NL", {
                year: "numeric",
                month: "long",
                day: "numeric",
            }).format(TODAY),
            TITLE = `Jouw chat transcript van ${DATE_STRING}`,
            TITLE_NEXT_PAGE = TITLE + " (vervolgd)";
        let _messages = transformChatMessages(messages);

        doc.setFontSize(PDF_TITLE_FONT_SIZE);
        doc.text(TITLE, PDF_X_OFFSET, PDF_TITLE_Y_POS);
        doc.setFontSize(PDF_MESSAGE_FONT_SIZE);

        let pageHeight = doc.internal.pageSize.height - PDF_Y_MARGIN;
        let splitMessages = doc.splitTextToSize(_messages, PDF_MAX_LINE_LENGTH);

        let currentYPosition = PDF_Y_OFFSET_MESSAGES;
        for (let lineNumber = 0, totalLines = splitMessages.length; lineNumber < totalLines; lineNumber++) {
            doc.text(splitMessages[lineNumber], PDF_X_OFFSET, currentYPosition);
            currentYPosition = currentYPosition + PDF_MESSAGE_FONT_SIZE / 2;
            if (currentYPosition > pageHeight) {
                doc.addPage();
                doc.setFontSize(PDF_TITLE_FONT_SIZE);
                doc.text(TITLE_NEXT_PAGE, PDF_X_OFFSET, PDF_TITLE_Y_POS);
                doc.setFontSize(PDF_MESSAGE_FONT_SIZE);

                currentYPosition = PDF_Y_OFFSET_MESSAGES;
            }
        }

        if (isApp()) {
            const blob = new Blob([doc.output()], {type: "application/pdf"});
            const reader = new FileReader();
            reader.addEventListener("load", event => {
                window.MKWebViewBridge.sendEvent({
                    app: APP_ID,
                    type: 'MK_OPEN_PDF',
                    payload: {
                        url: event.target.result
                    }
                });
            }, false);

            reader.readAsDataURL(blob);
        } else {
            doc.save(PDF_TRANSCRIPT_FILENAME);
        }
        return true;
    }).catch(error => {
        console.log(error);
        return false;
    });
};

export const htmlDecode = (input) => {
    let messageHTMLNode = document.createElement("div");
    messageHTMLNode.innerHTML = input;
    return messageHTMLNode.textContent;
};

export const strip = (html) => {
    return html.length
        ? new DOMParser().parseFromString(html, "text/html").body.textContent
        : "";
};

export const getMessages = (marketType) => {
    if (marketType === null || marketType === undefined) {
        marketType = getMarketType();
    }
    if (marketType !== MARKET.CONSUMER && marketType !== MARKET.BUSINESS) {
        console.warn("market type not set correctly, defaulting to consumer.");
        // default to consumer market messages
        return MESSAGES.CM;
    }
    return MESSAGES[marketType];
};

export const getIntentID = () => {
    const param = getCookieParam("intent_id");
    return param;
};

export const getMarketType = () => {
    const param = getCookieParam("market_type");
    return param === "bm" ? MARKET.BUSINESS : MARKET.CONSUMER;
};

export const removeStartingQuestion = () => {
    removeCookieParam("starting_question");
    // for more consistency remove starting question from url too
    try {
        const url = new URL(window.location);
        url.searchParams.delete("starting-question");
        window.history.replaceState({}, document.title, url.href);
    } catch {
    }
};

export const isMobile = () => {
    return Boolean(window.navigator.userAgent.match(/android|iphone/i));
};

export const isApp = () => {
    return getCookie("mijnkpnapp") === "1";
};

export const openFeedback = () => {
    tags.chatFeedback();
    if(getCookieParam("market_type") === "lce" || getCookieParam("mix-env") === "lce") {
        customMopinionButton('4131c46f2ddce3f0ce061a935c80b8a5c3f371a0');  // LCE form
    } else {
        customMopinionButton('cdbc4e702f848a5b000af6f8206325b4a5d6ad9f');
    }
};

export const userInputEnabled = (state) => {
    const handover_in_progress = state.webSocketReducer.conversation.handover_in_progress;
    const messages = state.webSocketReducer.conversation.messages;
    const last_message = messages[messages.length - 1];

    // can't type when connection is closed
    if (state.webSocketReducer.ws.status === WS_CONNECTION_RECONNECTING
        && state.webSocketReducer.ws.reconnect >= WS_WARNING_RETRY_ATTEMPTS) {
        return false;
    }

    // can't type when connection is closed
    if (state.webSocketReducer.ws.status === WS_CONNECTION_CLOSED) {
        return false;
    }

    // if there is no messages at all, we must assume that you can type
    if (last_message === undefined) {
        return true;
    }

    return (
        // we can type if we're not in a handover
        !handover_in_progress &&
        // and we're talking to a human or the last message is not from us
        (state.webSocketReducer.conversation.type === HUMAN ||
            last_message.actor !== ACTOR_USER)
    );
};

export const openCSATPage = ({ postChatURL, messages, liveAgentSessionID, disconnectedBy }) => {
    let csatForm = document.createElement("form");
    csatForm.setAttribute("method", "post");
    csatForm.setAttribute("action", postChatURL);
    csatForm.setAttribute("target", "Salesforce Survey");

    let messageField = document.createElement("input");
    messageField.setAttribute("type", "hidden");
    messageField.setAttribute("name", "transcript");
    messageField.setAttribute("value", transformChatMessages(messages));

    let sessionIDField = document.createElement("input");
    sessionIDField.setAttribute("type", "hidden");
    sessionIDField.setAttribute("name", "chatKey");
    sessionIDField.setAttribute("value", liveAgentSessionID);

    let disconnectReason = document.createElement("input");
    const reason = disconnectedBy === "ended by agent" ? "agent" : "clientIdleTimeout";
    disconnectReason.setAttribute("type", "hidden");
    disconnectReason.setAttribute("name", "disconnectedBy");
    disconnectReason.setAttribute("value", reason);

    csatForm.appendChild(messageField);
    csatForm.appendChild(sessionIDField);
    csatForm.appendChild(disconnectReason);

    document.body.appendChild(csatForm);
    if (!isMobile()) {
        window.open("", "Salesforce Survey");
    }
    csatForm.submit();
};

export const getShadowRoot = () => document.getElementById(SHADOW_ROOT_ID).shadowRoot;

export const closeWebview = () => {
    if (isApp()) {
        window.MKWebViewBridge.sendEvent({
            app: APP_ID,
            type: 'MK_WEBVIEW_CLOSE',
            payload: {
                clearCache: false
            }
        });
    }
};

export const authAwareFetch = (input, init) => {
    if (!!window.kpnCore) {
        return window.kpnCore.authStatus()
            .then(({authenticated}) => authenticated ? window.kpnCore.authFetchRaw : fetch)
            .then((fetchFunc) => fetchFunc(input, init));
    } else {
        return fetch(input, init);
    }
};
