export const getMarketType = () => {
    const match = document.cookie.match(/CE_PARAMS=([^;]+);?/);
    if (match != null) {
    	const config = JSON.parse(decodeURIComponent(match[1]));
    	if (config !== null && config.market_type === 'bm') {
    	    return 'zm';
    	}
    }
    return 'cm';
};

export const customMopinionButton = (formKey, domain) => {
    let scriptId = 'mopinionFeedbackScript';

    let loadMopinion = function() {
        let s = document.createElement('script'),
            f = function () {
                try {
                    window.srv.open({
                        key: formKey,
                        domain: domain ? domain : 'kpn.mopinion.com',
                        divName: 'body',
                        trigger_method: 'passive',
                        openForm: true
                    });
                } catch (e) {
                }
            };
        s.onreadystatechange = f;
        s.onload = f;
        s.id = 'mopinionFeedbackScript';
        s.src = 'https://collect.mopinion.com/assets/surveys/2.0/js/survey.min.js';
        s.addEventListener('load', function () {
            window.srv.openModal(formKey);
        });
        document.body.appendChild(s);
    };

    try {
        if (document.getElementById(scriptId)) {
            window.srv.openModal(formKey);
        } else {
            loadMopinion();
        }
    } catch (e) {
        console.error(e);
    }
};
