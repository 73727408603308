const pushTag = payload => {
    if ( window.adobeDataLayer ) {
        window.adobeDataLayer.push(payload);
    }
};

const buttonClick = ({ module, label, buttonType }) => ({
    event: "userInteraction",
    interaction: {
        eventInfo: {
            eventName: "user",
            eventAction: "click",
        },
        category: {
            primaryCategory: buttonType
        },
        attributes: {
            moduleName: module,
            position: "main body",
            element: buttonType,
            label: label,
        }
    }
});

const chatComponentEvent = ({ name, triggerParameters, subCategory, product }) => ({
    event: "chatComponentEvent",
    component: [{
        componentInfo: {
            componentName: name,
            timestamp: Date.now(),
            triggerParameters: triggerParameters,
            origin: window.location.href,
        },
        category: {
            primaryCategory: "nuance chat",
            subCategory: subCategory,
        },
        user: {
            productPossession: product
        }
    }]
});

const impression = ({ label }) => ({
    event: "chatImpression",
    impression: {
        eventInfo: {
            eventAction: "viewed",
        },

        attributes: {
            moduleName: "chat body",
            position: "main body",
            element: "notification",
            label: label,
        }
    }
});

const formatTriggerParameters = triggerParameters => {
    // rename keys
    let { market_type, sub_journey, ...parametersCopy} = triggerParameters;
    parametersCopy = {market: market_type, subjourney: sub_journey, ...parametersCopy};

    return Object.entries(parametersCopy)
    .map(([key, value]) => `${key}=${value ? value : 'no value'}`)
    .join("|");
};

const headerClick = label => pushTag(buttonClick({module: "chat header", buttonType: "button", label }));
const chatDownload = () => headerClick("chat downloaden");
const chatFeedback  = () => headerClick("chat beoordelen");
const chatRestart  = () => headerClick("chat opnieuw starten");
const chatClose  = () => headerClick("chat sluiten");
const chatMinimize  = () => headerClick("chat minimize");
const chatMaximize  = () => headerClick("chat maximize");
const internalLinkClick = label => pushTag(buttonClick({module: "chat body", buttonType: "internallink", label}));
const externalLinkClick = label => pushTag(buttonClick({module: "chat body", buttonType: "externallink", label}));
const loginOffered = () => pushTag(impression({label: "login offered"}));
const loginLinkClick = label => pushTag(buttonClick({module: "chat body", buttonType: "loginlink", label}));
const loginSuccess = () => pushTag(impression({label: "login successful"}));
const appStart = triggerParameters => pushTag(chatComponentEvent({
        name: "chatApp",
        subCategory: "prechat",
        triggerParameters: formatTriggerParameters(triggerParameters)
    }));
const taggingEvent = (payload, cookieConfig) => {
    switch (payload.tag_type) {
        case "intent":
            pushTag(chatComponentEvent({
                name: "chatApp",
                subCategory: "prechat",
                triggerParameters: formatTriggerParameters({...cookieConfig, intent_id: payload.tag_payload})
            }));
            break;
        case "phone_number":
        case "callback":
            pushTag(impression({label: `${payload.tag_type}-${payload.tag_payload}`}));
            break;
        case "product":
            pushTag(chatComponentEvent({
                name: "chatApp",
                subCategory: "prechat",
                triggerParameters: formatTriggerParameters({...cookieConfig}),
                product: payload.tag_payload
            }));
            break;
        default:
            break;
    }
};
const handoverEvent = () => pushTag(impression({label: "handover"}));

export {
    chatDownload,
    chatFeedback,
    chatRestart,
    chatClose,
    chatMinimize,
    chatMaximize,
    internalLinkClick,
    externalLinkClick,
    loginOffered,
    loginLinkClick,
    loginSuccess,
    appStart,
    taggingEvent,
    handoverEvent
};